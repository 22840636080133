//import { Link, FormattedMessage } from "gatsby-plugin-intl"
import PortableText from "../components/portableText"
import localize from "../components/localize"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"

export const query = graphql`
  query ($slug: String) {
    sanityTalleres(slug: { current: { eq: $slug } }) {
      title {
        _type
        es
        pt
        en
      }
      mainImage {
        asset {
          url
          fluid(maxWidth: 3000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody
    }
  }
`

const TalleresTemplatePage = ({ data }) => (
  <Layout>
    <SEO
      title={data.sanityTalleres.title}
      image={data.sanityTalleres.mainImage.asset.url}
    />
    <Helmet>
      <body className="invert-nav" />
    </Helmet>
    <div className="flex flex-col-reverse mt-24 border-b border-gray-500 xl:grid xl:grid-cols-2">
      {data.sanityTalleres.mainImage && (
        <div className="relative z-0 w-full min-h-screen overflow-hidden bg-gray-100 opacity-100 2xl:sticky 2xl:top-0">
          <Img
            fluid={data.sanityTalleres.mainImage.asset.fluid}
            className="object-cover w-full h-full"
          />
        </div>
      )}
      <div className="relative z-10 w-full min-h-screen px-6 pt-24 mx-auto bg-gray-100 xl:px-24">
        <h1 className="font-serif text-6xl text-gray-900">
          {data.sanityTalleres.title}
        </h1>
        <div className="font-sans prose prose-lg text-left text-gray-900 ">
          {data.sanityTalleres._rawBody && (
            <PortableText blocks={data.sanityTalleres._rawBody} />
          )}
        </div>
        <Link
          to={`/contact`}
          className="inline-block px-8 py-2 mt-12 font-serif text-lg font-bold tracking-wide text-gray-100 uppercase duration-500 bg-gray-800 shadow-lg hover:bg-gray-100 hover:text-gray-900"
        >
          <FormattedMessage id="contact_us" />
        </Link>
      </div>
    </div>
  </Layout>
)

export default localize(TalleresTemplatePage)
